@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,500;0,700;1,100&family=Inter:wght@400;500;700&display=swap');

.App {
	text-align: left !important;
}

html, body, #root, .App {
	height: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clearfix::after {
	content: "";
   display: block;
   clear: both;
}

/* ------------- GENERIC --------------*/

/* Colors */

:root {
	--bgColor: #F3F4F6;
    --pink: #8A90AD;
 	--blue : #000094;
 	--black: #022039;
 	--lightGrey : #F3F4F6;
 	--turquoise: #8A90AD;
 	--green: #2F9898;
 	--lightGreyTransparent: #0a102a1c;
 	--lightYellow : #FFF6EF;
	--purple : #f3bfff;
	--skyBlue : #D4F7F7;
	--lightBlue : #CDE5E3;

}


.bgBlue {
	background: var(--black);
}

.bgOrange {
	background: var(--orange);
}

/* Typo */


body {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	background: var(--bgColor);
	line-height: 1.5;
	color: var(--black);
	overflow-y: scroll;
	font-smooth: always;
}

h1, h2, h3, h4 {
	font-family: 'Inter';
}


.container {
	max-width: 1240px;
	margin: 0 auto;
}

.container768 {
	max-width: 768px;
	margin: 0 auto;
}

.w1240 { width: 1240px; }
.w100p {width: 100%;}
.w50p {width: 50%;}

.floatLeft { float: left; }
.floatRight { float: right; }
strong {font-weight: 700;}

.textCenter { text-align: center; }
.p01em { padding: 0 1em 1em 0; }
.absolute {position: absolute;}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.helpIcon {
	display: block;
	width: 1.5em;
	height: 1.5em;
	background: url(img/helpIcon.svg) center no-repeat;
	right: 0;
	top: 1em;
	position: absolute;
}

.questionLink {
	color: var(--black);
	text-decoration: underline;
}

.questionLink:after {
	content: ' 🤔';
}

.fixed {
	position: fixed;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
}


/* ------------- HEADER --------------*/

header {
	border-bottom: 2px solid var(--lightGrey);
	background: #fff;
}

.header_topBar {
	text-align: left;
	background: linear-gradient(90.77deg, var(--blue) 0, var(--black) 24.42%);
	padding: 1em;
	color: #fff;
	vertical-align: middle;
	box-shadow: inset 0 -3px 1.5em rgba(0,0,0,0.1);
}

.header_topBar img {
	text-align: left;
	font-weight: 2em;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1em;
}

.header_topBar a {
	text-decoration: none;
	font-weight: 700;
	color: #fff;
}


.header_navBar {
	padding: 2em 0;
}

.header_navBar > * > a {
	text-decoration: none;
	float: left;
}

.mainNav, .secondaryNav {
	float: right;
	clear: both;
}

.mainNav .metaLinks {
	position: fixed;
	bottom: 1em;
	left: 1em;
}


.secondaryNav {
	margin-right: 2em;
	margin-bottom: 4em;
	font-size: 0.875em;
}

.mainNav li {
	display: inline-block;
	position: relative;
}

.mainNav li button {
	font-family: Inter;
	background: none;
	padding: 1em;
	font-size: 1em;
	border: 3px solid transparent;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 700;
	color: var(--black);
	border-radius: 2em;
	transition: all 0.150s;
}

.mainNav li button.current {
	color: var(--pink);
}

.mainNav li button.roundPink {
	background-color: var(--pink);
	color: #fff;
	border-radius: 2em;
	padding: 0.5em 1em;
	margin-right: 1em;
	text-transform: initial;
}

.mainNav li button.connectWallet {
	background-image: url(img/wallet.svg);
	background-size: 1em;
	background-position: 92% center;
	background-repeat: no-repeat;
	padding: 0.5em 3em 0.5em 1em;
	text-transform: initial;
}

.mainNav li button.pinkButton {
	padding: 0.5em 1em 0.5em 1em;
	text-transform: initial;
	margin-right: 1em
}

.mainNav li button.roundblue {
	background: var(--blue);
	color: #fff;
	border-radius: 2em;
	padding: 0.5em 1em;
	margin: 0 1em;
	text-transform: initial;
}

.mainNav li button:active, .mainNav li button:focus  {
	border-color: var(--lightGrey);
}


.mainNav li > ul {
	background: #fff;
   padding: 3em;
   width: 600px;
   position: absolute;
   right: 0;
   top: 5em;
   display: none;
   border-radius: 0.5em;
	box-shadow: 
	0px 22px 81px -11px rgba(2, 32, 57, 0.16),
	0px 2px 2px 0 rgba(2, 32, 57, 0.07);;
}

.mainNav > li > ul:active {
	display: block !important;
}

.mainNav li button:focus + ul {
	display: block;
	z-index: 3;
}

.mainNav > li > ul > li {
	width: 49%;
	box-sizing: border-box;
}

.mainNav > li > ul > li * {
	display: inline-block;
	vertical-align: top;
	color: #111;
}

.mainNav > li > ul li a {
	text-decoration: none;
	padding: 1em;
	border-radius: 3px;
	font-family: Inter, sans-serif;
}

.mainNav > li > ul li a:hover {
	background: var(--bgColor);
}

.mainNav > li > ul li div {
	padding-left: 1em;
	width: 75%;
}

.mainNav li > ul li div h4 {
	color: var(--black);
	font-weight: 700;
	letter-spacing: 2px;
}

.mainNav .resourcesNav * {
	display: block;
	padding: 0.25em;
} 

.mainNav .resourcesNav {
	width: 768px;
} 

.mainNav > li button:focus + .resourcesNav {
	display: table-row;
}

.mainNav .resourcesNav > li {
	display: table-cell;
	width: 25%;
	padding-right: 1em;
}

.mainNav .resourcesNav li div {
	width: 100%;	

}
.mainNav li .resourcesNav h4 {
	height: 2em;
	margin-bottom: 1.5em;
	width: 75%;
}

.mainNav .resourcesNav > li {
	padding-bottom: 0
}
.mainNav .resourcesNav > li a {
	margin-left:-0.5em;
	padding: 0.75em;
	border-top: 2px solid var(--bgColor);
}

.secondaryNav li a {
	padding: 1em;
	color: #fff;
	text-decoration: none;
	border-radius: 3px;
	font-family: Inter;
	font-weight: 700;
	letter-spacing: 1px;
	display: block;
}

section.hero {
	padding: 6em 0;
}

.hero h1 {
	font-weight: 700;
	font-size: 2em;
	line-height: 1.5;
	width: 50%;
}

.hero h2 strong {
	color: var(--orange);
}


/* ------------ CATALOGUE ------------- */

/* generique */


.flex {
	display: flex;
	flex-flow: row ;
}

.flex.flexStart {
	align-items: flex-start;
}

.flex.flexEnd {
	align-items: flex-end;
}

.flex.column {
	flex-flow: column ;
}

.flex.wrap {
	flex-flow: row wrap;
}

.flex.center {
	align-items: center;
}

.flex-1 {
	flex: 1;
}

/* ---- Moteur de recherche ----- */

.searchEngine {
	width: 100%;
}

.searchEngine > div {
	flex: 1;
	margin-right: 1em;
}

.searchEngine > div:first-child {
	flex: 50%;
	position: relative;
}

.searchEngine > div > div:first-child {
	padding-left: 1em;
}

.searchEngine input[type=text], .searchEngine select {
	border: none;
	outline: 1px solid #0a102a1c;
	font-family: 'Inter', sans-serif;
	position: relative;
	display: block;
	padding: 1em;
	border-radius: 0.5em;
	margin: 1em;
	width: 100%;
	box-sizing: border-box;
	box-shadow: 
		0px 22px 81px -11px rgba(2, 32, 57, 0.16),
		0px 2px 2px 0 rgba(2, 32, 57, 0.07);
	background: #fff;
	font-weight: 700;
}

.searchEngine select {
	width: auto;
	cursor: pointer;
}

.searchEngine input[type=text]:hover, .searchEngine select:hover {
	outline-color: rgba(0,0,0,.15);
}

.searchEngine input[type=text]:focus, .searchEngine select:focus {
	background: #fff;
}

.searchEngine input[type=text] {
	padding-left: 4em;
}

.searchEngine input[type=text]:focus, .searchEngine select:focus {
	outline-color: var(--black);
}

.searchEngine > div:first-child:before {
	display: block;
	content: '';
	position: absolute;
	background: url(img/searchIcon.svg);
	width: 1em;
	height: 1em;
	left: 2em;
	top: 1.75em;
	z-index: 2;
}

/* ---- Contenu du catalogue ----- */

.catalogueCell {
	transition: all 0.150s;
	flex: 1 0 20%;
	/* background: #fff; */
	/* background: #8A90AD; */
	padding: 1.25em;
	box-shadow: 
		0px 22px 81px -11px rgba(2, 32, 57, 0.16),
		0px 2px 2px 0 rgba(2, 32, 57, 0.07);
	border-radius: 0.5em;
	margin: 1em;
	display: flex;
	flex-flow: column wrap;
	max-width: 27.5%;
	cursor: pointer;
	border: 3px solid transparent;
	outline: 1px solid #0a102a1c;
}

.catalogueCell.onBuilding{
		border: 5px solid;
		border-image: repeating-linear-gradient(
		  -55deg,
		  #000,
		  #000 20px,
		  #ffb101 20px,
		  #ffb101 40px
		) 10;
	  }

.catalogueCell:hover {
	border-color: var(--black);
}

.catalogueCell .title img {
	width: 15%;
	padding-right: 1em;
}

.catalogueCell .title h2 {
	font-weight: 700;
}
.catalogueCell .title ul li {
	margin-right: 0.5em
}
.catalogueCell .title ul li .tag{
	font-size: 0.75em;
	padding: 0.5em 1em;
	/* background: var(--lightGrey); */
	border-radius: 0.5em;
}

.tagPurple{
	background: var(--purple);
}

.tagSkyBlue{
	background: var(--skyBlue);
}

.tagLightBlue{
	background: var(--lightBlue);
}

.catalogueCell .description {
	padding-top: 1.5em;
	font-family: Inter, sans-serif;
}

.catalogueCell.addService {
	background: none;
	border: 3px dotted var(--black) ;
	box-shadow: none;
}

.catalogueCell.addService:hover {
	border-style: solid;
}

.catalogueCell.addService button {
	border: none;
	background: none;
	font-family: Inter, sans-serif;
	font-weight: 700;
	color: var(--black);
	cursor: pointer;
	font-size: 1em;
	padding: 3em;
	align-items: center;
}

footer {
	margin-top: 6em;
	padding: 3em 0;
	background: #fff;
}

footer .container div {
	width: 25%;
	font-weight: 700;
	font-size: 1em;
	padding: 3em 0;
}

footer .container div img { margin-bottom: 3em}
footer a { text-decoration: none; color: var(--blue); display: block; padding: 0.5em 0 0; }

/** ------------------- BREADCRUMB ----------------------**/

.breadcrumb {
	padding: 1.5em 0;
	width: 100%;
	border-bottom: 1px solid var(--lightGreyTransparent);
	margin-bottom: 1em;
}

.breadcrumb li {
	position: relative;
}


.breadcrumb li button {
	background: none;
	border: none;
	padding: 1em;
	text-decoration: none;
	color: var(--black);
	font-weight: 700;
	cursor: pointer;
}

.breadcrumb li.back button {
	position: relative;
	padding-left: 1.5em;
}

.breadcrumb li.back button:before {
	position: absolute;
	display: block;
	content: '';
	background: url(img/back.svg) center no-repeat;
	width: 0.75em;
	height: 1em;
	top: 1.2em;
	left: 0
}

.breadcrumb li:after {
	display: block;
	content: '/';
	position: absolute;
	right: -0.3em;
	top: 0.5em;
}

.breadcrumb li:last-child:after,.breadcrumb li.back:after  {
	display: none;
}

.breadcrumb li.current button {
	color: var(--pink);
}

/** ------------------- PAGE SERVICE ------------------ **/

.servicePage main {
	border-radius: 0.5em;
	box-shadow: 
		0px 22px 81px -11px rgba(2, 32, 57, 0.16),
		0px 2px 2px 0 rgba(2, 32, 57, 0.07);
	background: #fff;
	max-width: 960px;
}

.servicePageTitle {
	align-items: center;
	border-bottom: 2px solid var(--lightGrey);
	padding-bottom: 1em;
	padding: 1em;
}

.servicePageTitle > img {
	background: #fff;
	width: 4em;
	margin-right: 2em;
	background: #fff;
	padding: 1em;
}

.servicePageTitle > div {
	flex: 1;
}

.servicePageTitle > div h1 {
	font-size: 1.75em;
	font-weight: 700;
}

.servicePageTitle > div a {
	text-decoration: none;
	color: var(--pink);
	position: relative;
}

.servicePageTitle > div .resourceTags li {
	margin-right: 0.5em
}

.servicePageTitle > div .resourceTags li .tagList {
	border-left: 2px solid var(--lightGrey);
	padding-left: 1em;
}

.servicePageTitle > div .resourceTags li .tagList li {
	padding: 0.25em 1em;
	border-radius: 2em;
	/* background: var(--lightGrey); */
	font-size: 0.875em;
	font-weight: 700;
}

.servicePageTitle > div .resourceTags .resourceType {
	background: url(img/datasetIconWhite.svg) var(--black) center no-repeat;
	background-size: 1em;
	width: 2em;
	height: 2em;
	border-radius: 2em;
}

.servicePageTitle > div .resourceTags .isResourceOpen.locked {
	background: url(img/locked.svg) var(--pink) center no-repeat;
	background-size: 0.75em;
	width: 2em;
	height: 2em;
	border-radius: 2em;
} 

.servicePageTitle > div a:after {
	content: '';
	width: 1.5em;
	height: 1.5em;
	display: inline-block;
	background: url(img/link.svg) ;
	position: absolute;
	right: -2em;
}

.servicePageTitle .ctas a {
	text-decoration: none;
	padding: 1em 2em;
	display: block;
}

.servicePageTitle .ctas img {
	width: 4em;
}

.servicePageTitle .ctas span {
	font-size: 0.75em;
}

.servicePageTitle .ctas a.useService {
	font-weight: 700;
}

.servicePageContent {
	font-family: Inter, sans-serif;
}

.servicePageContent .serviceDescription .warning {
	padding: 1.5em;
	background: var(--lightYellow);
	border-radius: 0.5em;
	margin-bottom: 1.5em;
}

.servicePageContent .serviceDescription {
	border-right: 1px solid var(--lightGreyTransparent);
	padding: 3em;
}

.servicePageContent .serviceDetails {
	font-size: 	0.875em;
}

.servicePageContent .serviceDetails .button {
	width: 100%;
	text-align: left;
	box-sizing: border-box;
	white-space: nowrap;
}

.servicePageContent .serviceDetails table .button {
	overflow: hidden;
	text-overflow: ellipsis;
    max-width: 250px;
}

.servicePageContent .serviceDetails > * {
	padding: 0.75em;
	border-bottom: 1px solid var(--lightGreyTransparent);
}

.servicePageContent .serviceDetails table {
	margin: 1em;
	border-bottom: none;
}

.servicePageContent .serviceDetails tr {
	border-bottom: 1px solid var(--lightGreyTransparent);
}


.servicePageContent .serviceDetails thead tr td {
	padding: 0;
	font-size: 0.875em;
	vertical-align: top;
}

.servicePageContent .serviceDetails tr td {
	padding: 1em 0;
	vertical-align: top;
	font-size: 0.875em;
}

.servicePageContent .serviceDetails tr td:first-child {
	text-transform: uppercase;
	font-weight: 700;
	color: var(--pink);
	width: 33%;
}

.servicePageContent h2 {
	font-size: 1.5em !important;
	margin: 0 0 0.5em;
	font-weight: 700;
	color: var(--black);
	text-transform: initial;
}

/**** -------------- DASHBOARD ------------ ****/ 

.block {
	display: block;
}

.alert {
	position: relative;
}

.alert:after {
	position: absolute;
	content: '!';
	color: var(--pink);
	top: -0.25em;
	right: 0.25em;
	font-weight: 700;
	font-size: 1.25em;
	font-family: Inter, sans-serif;
}

.dashboard header {
	background: none;
	border-bottom: 1px solid #0a102a1c;
}

.dashboard header .back {
	border: none;
	background: none;
	font-weight: 700;

}

.dashboard {
	display: flex;
	align-items: stretch;
	height: 100%
}

.contextNav {
	padding: 2em 2em 0 2em;
}

.dashboard .mainNav {
	position: fixed;
	top: 0;
	bottom: 0;
	font-size: 0.875em;
	background: #fff;
	float: none;
	width: 20%;
	max-width: 320px;
	min-width: 280px;
	border: none;
	margin-right: 1em;
	box-shadow: 
		0px 22px 81px -11px rgba(2, 32, 57, 0.16),
		0px 2px 2px 0 rgba(2, 32, 57, 0.07);
	outline: 1px solid #0a102a1c;
}

.dashboard .mainNav li > ul {
	padding: 0;
	top: 3em
}

.dashboard .mainNav li > ul li {
	margin: 1em
}

.dashboard .mainNav li button {
	padding: 0;
}

.dashboard .mainNav li button:focus + ul {
	width: 190px;
}

.dashboard aside header {
	border: none;
}

.userService {
	padding: 1em;
	margin: 1em
}

.userService .userInformations {
	flex: 1;
}

.userService button {
	width: 3em;
	height: 3em;
	background: none;
	border: none;
	background-repeat: no-repeat;
	cursor: pointer;
	border-radius: 0.5em;
}

.userService button:hover {
	background-color: #0a102a1c ;
}

.userService button.ellipsis {
	background-image: url(img/ellipsis.svg) ;
	background-repeat: no-repeat;
	background-position: center;
}

.userService button.notifications {
	background: url(img/notif.svg);
	background-position: center ;
	background-repeat: no-repeat;
	display: none;
}

.contextNav li:last-child {
	flex: 1;
	padding-right: 1em;
	text-align: right;
}

.actAsButton {
	cursor: pointer;
	border-radius: 0.5em;
}

.actAsButton:hover {
	background-color: var(--lightGrey);
}

.appNav {
	padding: 1em;
}

.appNav li {
	padding: 1em 1em 1em 3em;
	margin-bottom: 1em;
	font-weight: 700;
	text-transform: uppercase;
	position: relative;
}

.appNav li.current {
	background: var(--lightGrey);
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 74px -20px rgba(0, 0, 0, 0.1);
	outline: 1px solid #0a102a1c;
}

.appNav li:before {
	content: '';
	display: block;
	position: absolute;
	height: 1.25em;
	width: 1.25em;
	left: 1em;
	top: 1.25em
}

.appNav li.catalogue:before {
	background: url(img/searchIcon.svg) center no-repeat;
}

.appNav li.serviceOffering:before {
	background: url(img/serviceOffering.svg) center no-repeat;
}

.appNav li.feedback:before {
	background: url(img/feedbackIcon.svg) center no-repeat;
}

.dashboard .main {
	padding: 1em;
    margin-left: 320px;
}

.sectionContent {
	padding-bottom: 6em;
	margin-bottom: 6em;
	border-bottom: 1px solid #0a102a1c;
}

.dashboard .main header {
	padding-bottom: 2em;
	position: relative;
}

.dashboard .main header h1 {
	font-size: 1.5em;
	font-weight: 700;
}

.dashboard .main header h2 {
	color: var(--pink);
	font-size: 0.875em;
}

.dashboard .main h1 img {
	width: 1em;
	position: relative;
	top: 0.125em;
	margin-right: 0.5em
}

.button img {
	max-height: 1.5em;
	position: relative;
	top: 0.25em;
	margin-left: 1em;
}

.button {
	line-height: 1.75;
	border-radius: 0.5em;
	padding: 0.5em 1em;
	cursor: pointer;
	font-family: 'Inter', sans-serif;
	border: 3px solid #0a102a03;
	transition: all 0.2s;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 74px -20px rgba(0, 0, 0, 0.1);
	font-weight: 700;
	font-family: Inter, sans-serif;
	outline: 1px solid var(--lightGreyTransparent);
	font-family: Inter, sans-serif;
}

.button.external {
	padding: 0.5em 4em 0.5em 1em;
	background-image: url(img/link.svg);
	background-position: 92% center;
	background-repeat: no-repeat;
}

.button.export {
	padding: 0.5em 4em 0.5em 1em;
	background-image: url(img/export.svg);
	background-position: 92% center;
	background-repeat: no-repeat;
}

.button.rounded {border-radius: 4em;}

.button:hover {
	border: 3px solid rgba(0, 0, 0, 0.1);
}

.button:active {
	border: 3px solid var(--pink);
}

.button.pinkButton {
	background-color: var(--pink);
	color: #fff;
}

.button.greenButton {
	background-color: var(--green);
	color: #fff;
}

.button.turquoiseButton {
	background-color: var(--turquoise);
	color: #fff;
}

.button.blackButton {
	color: #fff;
	background-color: var(--black);
}

.button.whiteButton {
	background-color: #fff;
	color: var(--black);
	font-weight: 700;
}


.sectionContent.empty {
	text-align: center;
}

.sectionContent.empty > p {
	padding: 5em 0 2em;
	font-family: Inter, sans-serif;
}

/**** -------------- ONBOARDING ------------ ****/

.modal {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(2px);
    background-color: var(--lightGreyTransparent);
    z-index: 1;
    overflow-y: scroll;
}

.closeButton {
	background: url(img/close.svg) left no-repeat;
	padding-left: 2em;
	line-height: 1.5em;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 1em;
	left: 4em;
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	font-size: 1em;
	color: var(--black);
}

.modal .form {
	padding-top: 0;
}

.modal .form .formGroup button {
	position: absolute;
    right: 0.5em;
    width: auto;
    top: 0.5em;
}

.onboarding {
	background: var(--lightGrey);
	text-align: center;
	display: flex;
   align-items: center;
   justify-content: center;
}

.onboardingStep {
	justify-content: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-width: 640px;
	max-width: 740px;
	box-sizing: border-box;
	width: 40%;
	padding: 5em;
	margin: 0 auto;
	background: #fff;
	border-radius: 0 0 0.5em 0.5em;
	z-index: 242;
	position: relative;
}

.onboardingStep h1 {
	text-transform: uppercase;
	font-weight: 700;
	padding-bottom: 2em;
}

.onboardingStep p {
	padding-bottom: 2em;
	font-family: Inter, sans-serif;
	width: 75%;
	margin: 0 auto;
	text-align: center;
}

.onboardingStep h2 {
	text-align: left;
	font-weight: 700;
	width: 100%;
	margin: 1em 0;
}

.onboardingStep .flex-1 {
	padding-right: 3em;
}

.button.connectMetamask {
	line-height: 3em;
	width: 100%;
	margin-bottom: 0.5em
}

.button.connectWalltId {
	line-height: 3em;
	width: 100%;
	margin-bottom: 0.5em
}

.button.connectBCD {
	line-height: 3em;
	width: 100%;
	margin-bottom: 0.5em
}

.button.disabled {
	color: var(--pink);
}

.button.disabled span {
	font-size: 0.75em;
	padding-left: 1em;
	font-weight: 500;
}

input[type=submit].connectMetamask {
	background-image: url(img/metamask.png);
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: 2em;
	padding-right: 4em;
}

.button.connectMetamask:before {
	content: '';
	background: url(img/metamask.png) center no-repeat;
	background-size: 2em;
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
	top: 0.5em;
	margin-right: 1em
}

.button.connectWalltId:before {
	content: '';
	background: url(img/walltIdIcon.png) center no-repeat;
	background-size: 2em;
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
	top: 0.5em;
	margin-right: 1em
}

.button.connectBCD:before {
	content: '';
	background: url(img/BCDIcon.png) center no-repeat;
	background-size: 2em;
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
	top: 0.5em;
	margin-right: 1em
}

.onboardingStep .publicKey {
	font-family: 'Inter', sans-serif;
	color: var(--pink);
	font-weight: 700;
	background: #bf2ce51c;
	padding: 1em;
	border-radius: 0.5em;
}

.addResourceForm {
	padding-right: 4em;
	width: 480px;
}

.addResourceNav {
	margin-right: 8em;
}

.formProgression {
	padding: 3em 0;
	position: sticky;
	top: 2em;
	width: 280px;
}

.formProgression li {
	font-size: 0.875em;
	position: relative;
	text-overflow: ellipsis;
	width: 100%;
	margin-bottom: 1em;
	position: relative;
}

.formProgression .button.done {
	color: var(--green);
	outline-color: var(--green);
	background: #fff;
	outline-width: 2px;
}

.formProgression .button.disabled {
	opacity: 0.4;
}

.formProgression .button.done:after {
	position: absolute;
	right: 1em;
	top: 1.1em;
	background: url(img/littleTick.svg) center no-repeat;
	background-size: 1em;
	content: '';
	display: block;
	width: 1em;
	height: 1em;
}

.formProgression .button {
	border-radius: 0.5em;
	padding: 0.75em 1em;
}

.formProgression .sep img {
	position: absolute;
	right: 0;
}

.form {
	padding-top: 3em;
}

.form .formGroup {
	position: relative;
	cursor: text;
	padding-bottom: 1em
}

.form .formField {
	border: none;
	background: #fff;
	font-family: 'Inter', sans-serif;
	padding: 1.5em 2em 0.5em 1em;
	border-radius: 0.5em;
	line-height: 2.5;
	width: 100%;
	outline: 3px solid var(--pink);
	font-weight: 700;
	line-height: 2.25;
	box-sizing: border-box;
}

.form .formField.select {
	padding: 2.75em 1em 1.25em 1em;
	cursor: pointer;
}

.form .formField:hover {
	outline: 3px solid var(--black);
}


.form .formField + .formLabel {
	font-size: 0.75em;
	top: 0.75em;
    left: 1.5em;
	color: var(--black);
	position: absolute;
	transition: all 0.2s;
}

.form .formField:placeholder-shown + .formLabel {
	left: 1em;
	top: 1.25em;
	cursor: text;
	font-size: 0.875em;
	color: var(--pink);
	font-weight: 700
}

.form .formField::placeholder {
	color: transparent;
}

.form .formField:focus {
	outline-color: var(--black);
}


.form .formField:focus + .formLabel{
	outline: none;
	font-size: 0.75em;
	top: 0.5em;	
	left: 1em;
	color: var(--black);
}

.formLabel.button {
	text-align: center;
}

.form input[type=submit] {
	width: 100%;
}

.form .formGroup.radio input[type=radio] {  
	visibility: hidden;
	height: 0;
}

.form .formGroup.radio .selectServiceType .radioLabel {
	background-color: #fff;
	padding: 4em 1em 1.5em;
	display: block;
	border-radius: 0.5em;
	
}

.form .formGroup.radio .selectServiceType div {
	margin-right: 1em;
}

.form .formGroup.radio .selectServiceType div:last-child {
	margin-right: 0;
}

.form .formGroup.radio input[type=radio]:checked + .radioLabel {
	background-color: var(--turquoise);
	color: #fff;
}

.form h1 {
	font-size: 1.5em;
	font-weight: 700;
	margin-bottom: 1em;
}

label.forresourceTypeDataSet {
	background-image: url(img/datasetIcon.svg);
	background-size: 2em;
	background-repeat: no-repeat;
	background-position: center 1.5em;
} 

label.forresourceTypeService {
	background-image: url(img/serviceIcon.svg);
	background-size: 2em;
	background-repeat: no-repeat;
	background-position: center 1.5em;
} 
label.forresourceTypeCode {
	background-image: url(img/codeIcon.svg);
	background-size: 2em;
	background-repeat: no-repeat;
	background-position: center 1.5em;
} 

fieldset legend {
	text-align: left;
	text-transform: uppercase;
	font-weight: 700;
	color: var(--black);
	padding: 1em 0 0.75em;
	position: relative;
	padding-right: 1.5em;
	display: block;
	width: 100%;
	box-sizing: border-box;
}


.form .formGroup.select label {

}


/*********** ---------------- SERVICE OFFERING ----------------- ************/

.addService {
	top: 1em;
	right: 1em;
}

.serviceOfferingList .serviceOffer {
	flex: 1 1 24%;
	background: #fff;
	border-radius: 0.5em;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 74px -20px rgba(0, 0, 0, 0.1);
	padding: 1.5em;
	max-width: 31%;
	margin: 0.5em;
	box-sizing: border-box;
}

.serviceOfferingList .serviceOffer h2 {
	font-weight: 700;
	font-size: 1.125em;
}

.serviceOfferingList .serviceOffer .serviceTitle {
	position: relative;
	padding-bottom: 1em;
}

.serviceOfferingList .serviceOffer .serviceTitle .publishedOn {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 0.66em;
	color: var(--pink);
}

.serviceOfferingList .serviceOffer .serviceTitle .tags span {
	display: inline-block;
	padding: 0.33em 0.5em;
	font-size: 0.75em;
	color: rgba(0,0,0,0.65);
	font-weight: 700;
	border-radius: 0.5em;
}

.blueTag {
	background: #D4F7F7;
}

.pinkTag {
	background: #FBEBFF;
}

.serviceOfferingList .serviceOffer .serviceDescription {
	font-size: 0.875em;
}

.serviceOfferingList .serviceOffer .actionButtons {
	padding: 1.5em 0 1em;
}

.serviceOfferingList .serviceOffer .actionButtons > li {
	margin: 0 0.5em;
	position: relative;
}

.serviceOfferingList .serviceOffer .actionButtons > li:first-child {
	margin-left: 0;
}

.serviceOfferingList .serviceOffer .actionButtons > li:last-child {
	margin-right: 0;
}


.serviceOfferingList .serviceOffer .actionButtons > 	li button {
	text-align: left;
	background-position: 80% center;
	background-repeat: no-repeat;
	background-size: 1em;
	display: block;
	width: 100%;
}

.serviceOfferingList .serviceOffer .actionButtons button.edit {
	background-image: url(img/edit.svg);
}

.serviceOfferingList .serviceOffer .actionButtons button.publishState {
	background-image: url(img/downArrow.svg);
}

.serviceOfferingList .serviceOffer .actionButtons button.publishState + .changeState {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	padding: 0.5em;
	border-radius: 0.5em;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 74px -20px rgba(0, 0, 0, 0.1);
}


.serviceOfferingList .serviceOffer .actionButtons button.publishState:focus + .changeState {
	display: block;
}

.serviceOfferingList .serviceOffer .actionButtons button.publishState + .changeState li {
	display: block;
}

.serviceOfferingList .serviceOffer .actionButtons button.publishState + .changeState li:last-child {
	margin-top: 0.5em;
}

.serviceOfferingList .serviceOffer .serviceOfferId {
	text-align: right;
	border-top: 1px solid var(--lightGreyTransparent);
	padding-top: 1em;
}

.serviceOfferingList .serviceOffer .serviceOfferId button {
	border: none;
	font-size: 0.66em;
	background: transparent;
	cursor: pointer;
	color: var(--pink)
}

/************ ------------- CONTENT PAGE ------------ ***********/

.textBlock {
	padding: 2em 2em 3em 2em;
	background: #fff;
	border-radius: 0.5em;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 74px -20px rgba(0, 0, 0, 0.1);
}

.textBlock h1 {
	font-size: 2em;
	font-weight: 700
}

.textBlock a {
	text-decoration: none;
}

.textBlock ul {
	margin: 1em 0 1.5em
}

.textBlock ul li {
	margin-left: 1em;
	list-style: disc;
}

.textBlock h2 {
	font-size: 1.5em;
	font-weight: 700;
	margin: 1em 0 0.5em;
}

.textBlock h3 {
	font-weight: 700;
}

.textBlock p {
	margin-bottom: 2em;
}

.textBlock hr {
	margin: 3em 0 1em;
	border: 1px solid var(--lightGreyTransparent);
}

/* ---------------- PAGE D'ACCUEIL --------------- */

.homePage {
	background: var(--bgColor);
}

.homePage .mainNav li button:after {
	content: '';
	display: block;
	width: 0;
	border: 0.75em solid transparent;
	border-bottom-color: #fff ;
	display: none;
	position: absolute;
	left: 50%;
	margin-left: -0.75em;
	bottom: -1.5em;
}

.homePage .mainNav li button:focus:after {
	display: block;
}

.homePage .hero,.cataloguePublic .hero {
	background: #fff;
}

.cataloguePublic .hero {
	padding-bottom: 20em;
	margin-bottom: -15em;
}

.cataloguePublic .hero .container h1 {
	background: url(../assets/img/fedcat.png) left center no-repeat;
	background-size: 4em;
	padding-left: 6em;
} 

.homePage .hero {
	text-align: center;
}

.homePage .hero .container h1, .homePage .hero .container h2 {
	width: 66%;
	margin: 0 auto;
	line-height: 1.5;
}

.homePage .hero .container h2 {
	margin-top: 2em;
}

.homePage .hero .container img {
	width: 25%;
	margin: 4em	
}

.homePage .hero .container p {
	font-weight: 700;
	margin-top: 2em
}

.indexBlocs {
	padding: 0 6em 4em 0;
	margin-right: 2em;
	border-bottom: 2px solid var(--lightGreyTransparent);
}

.homeContent section {
	border-bottom: 2px solid #fff;
}

.homeContent section > section {
	padding: 6em 0;
}

.homeContent section > section h1 {
	font-size: 2em;
	font-weight: 700;

}

.homeContent section .flex-1:first-child * {
	margin: 1em 0
}

.homeContent section .flex-1:last-child {
	text-align: center;
}

.homeContent section img {
	width: 33%;
}

.homeContent section .button.whiteButton {
	background: #fff;
}